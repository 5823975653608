import { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';

import { loginRequest } from 'utils/msalConfig';

import { ErrorText, MSALButton } from './MicrosoftSignInButton.styled';

export default function MicrosoftSignInButton() {
  const { instance } = useMsal();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);

  const location = useLocation();

  const login = () => {
    if (!disabled) {
      setDisabled(true);
      setError(false);
      // had to use redirect instead of popup due to mobile popup issues
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/pull/5431
      instance
        .loginRedirect({
          ...loginRequest,
          redirectUri: '/login-redirect',
          redirectStartPage: '/login-redirect',
          prompt: 'select_account',
        })
        .catch((e) => {
          setDisabled(false);
          setError(true);
          // eslint-disable-next-line no-console
          console.error(e);
        });
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (location?.state?.loginError) {
      setError(true);
    }
  }, [location]);

  return (
    <div>
      <MSALButton type="button" onClick={login} disabled={disabled} />
      <ErrorText>{error && 'Error signing in with Microsoft'}</ErrorText>
    </div>
  );
}
