/**
 * User data and user-generated UI states.
 */

import { createStore, Effect, Event } from 'effector';

import { applyReducers } from 'effector-utils';

import {
  getUser,
  login,
  logout,
  setSelectedSiteId,
  showLoginPrompt,
  hideLoginPrompt,
  requestLoginCode,
  requestIntakeLoginCode,
  verifyCode,
  loginMicrosoft,
} from './actions';

import type { DoneHandler } from 'effector-utils';
import type { APIResponse } from 'utils/http';

import { User } from 'types/api';

type State = {
  authenticated: boolean | null;
  lastSelectedSiteId: number | null;
  showLoginPrompt: boolean;
  selectedSiteId: number | null;
  user: User | null;
};

const initialState: State = {
  authenticated: null,
  lastSelectedSiteId: null,
  selectedSiteId: null,
  user: null,
  showLoginPrompt: false,
};
const store = createStore(initialState);

type Reducers = {
  getUser: {
    action: Effect<Parameters<typeof getUser>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getUser>[0], State>;
  };
  login: {
    action: Effect<Parameters<typeof login>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof login>[0], State>;
  };
  loginMicrosoft: {
    action: Effect<Parameters<typeof loginMicrosoft>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof loginMicrosoft>[0], State>;
  };
  logout: {
    action: Effect<Parameters<typeof logout>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof logout>[0], State>;
  };
  requestLoginCode: {
    action: Effect<Parameters<typeof requestLoginCode>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof requestLoginCode>[0], State>;
  };
  requestIntakeLoginCode: {
    action: Effect<Parameters<typeof requestIntakeLoginCode>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof requestIntakeLoginCode>[0], State>;
  };
  verifyCode: {
    action: Effect<Parameters<typeof verifyCode>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof verifyCode>[0], State>;
  };
  hideLoginPrompt: {
    action: Event<Parameters<typeof hideLoginPrompt>[0]>;
    reducer: (state: State) => State;
  };
  setSelectedSiteId: {
    action: Event<{ siteId: number }>;
    reducer: (state: State, payload: { siteId: number }) => State;
  };
  showLoginPrompt: {
    action: Event<Parameters<typeof showLoginPrompt>[0]>;
    reducer: (state: State) => State;
  };
};

export const reducers: Reducers = {
  getUser: {
    action: getUser,
    done: (state, { result: { success, data } }) => ({
      ...state,
      authenticated: success,
      user: success ? data.user : null,
    }),
  },
  login: {
    action: login,
    done: (state, { result: { success, data } }) => ({
      ...state,
      authenticated: success,
      user: success ? data.user : null,
    }),
  },
  loginMicrosoft: {
    action: loginMicrosoft,
    done: (state, { result: { success, data } }) => ({
      ...state,
      authenticated: success,
      user: success ? data.user : null,
    }),
  },
  logout: {
    action: logout,
    done: (state, { result: { success } }) =>
      success
        ? {
            ...state,
            authenticated: false,
            user: null,
            showLoginPrompt: true,
            lastSelectedSiteId: null,
            selectedSiteId: null,
          }
        : state,
  },
  // Only here so that async status is available.
  requestLoginCode: {
    action: requestLoginCode,
    done: (state) => state,
  },
  requestIntakeLoginCode: {
    action: requestIntakeLoginCode,
    done: (state) => state,
  },
  // Only here so that async status is available.
  verifyCode: {
    action: verifyCode,
    done: (state) => state,
  },
  setSelectedSiteId: {
    action: setSelectedSiteId,
    reducer: (state, { siteId }) => ({
      ...state,
      selectedSiteId: siteId,
      lastSelectedSiteId: siteId,
    }),
  },
  showLoginPrompt: {
    action: showLoginPrompt,
    reducer: (state) => ({
      ...state,
      showLoginPrompt: true,
    }),
  },
  hideLoginPrompt: {
    action: hideLoginPrompt,
    reducer: (state) => ({
      ...state,
      showLoginPrompt: false,
    }),
  },
};

export default applyReducers({
  store,
  reducers,
  asyncResetReducers: [logout.done],
});
