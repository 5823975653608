import { useUnit } from 'effector-react';

import { userStore } from 'apps/specialist/state/user';
import { KeycloakService } from 'services/KeycloakService';

export function useIsAuthed() {
  const { authenticated } = useUnit(userStore);

  // @deprecated - don't need to check user once fully we migrate to keycloak
  // check for legacy user first
  if (authenticated === null) {
    return null;
  }
  return authenticated || KeycloakService.isLoggedIn();
}
