import { MsalProvider } from '@azure/msal-react';

import Providers from 'providers';
import { setDefaultAuthMethod } from 'utils/http';
import { msalInstance } from 'utils/msalConfig';

import Specialist from './Specialist';
import { AntdOverrides, GlobalStyles } from './SpecialistApp.styled';

import { CONNECT_TO_EXTERNAL_API } from 'constants/FeatureFlags';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export default function SpecialistApp() {
  return (
    <Providers>
      <MsalProvider instance={msalInstance}>
        <Specialist />
      </MsalProvider>
      <AntdOverrides />
      <GlobalStyles />
    </Providers>
  );
}
