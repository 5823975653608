import styled from 'styled-components';

import { spin } from 'styled/keyframes';

const DEFAULT_SIZE = 24;
const DEFAULT_COLOR = '#7772F7';
const DEFAULT_BACKGROUND_COLOR = '#FFF';

export const Spinner = styled.div<{
  size?: number;
  color?: string;
  backgroundColor?: string;
}>`
  border-radius: 50%;
  content: '';
  border: ${({ size }) => (size || DEFAULT_SIZE) / 8}px solid
    ${({ backgroundColor }) => backgroundColor || DEFAULT_BACKGROUND_COLOR} !important;

  /* Color of spinner */
  border-top: ${({ size }) => (size || DEFAULT_SIZE) / 8}px solid
    ${({ color }) => color || DEFAULT_COLOR} !important;
  width: ${({ size }) => size || DEFAULT_SIZE}px !important;
  height: ${({ size }) => size || DEFAULT_SIZE}px !important;
  animation: ${spin} 1s linear infinite;
`;
