import type { ChangeEvent, FocusEvent, ReactNode } from 'react';
import React, { useCallback, useState } from 'react';

import { useMedia } from 'react-use';

import { Heading, Step } from 'apps/specialist/components/PageOverlay';
import Input from 'apps/specialist/components/TextInput';
import { ReactComponent as AlertCircle } from 'assets/svg/alert-circle.svg';
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import KeycloakLoginButton from 'components/login/KeycloakLoginButton/KeycloakLoginButton';
import MicrosoftSignInButton from 'components/login/MicrosoftSignInButton';
import { Box, Text } from 'components/uikit';
import { KEYCLOAK_ENABLED } from 'config/env';
import theme from 'theme';
import cleanNumber from 'utils/cleanNumber';
import { formatPhoneInput } from 'utils/formatPhoneInput';
import isPhone from 'utils/validators/isPhone';

import {
  ButtonBox,
  EnterPhoneBox,
  EnterPhoneButton,
  GoogleAuthButton,
  PhoneLoginButton,
  PhoneLoginFields,
  TopRow,
} from './Login.styled';

type LoginProps = {
  name: string;
  active?: boolean;
  updateContentSize?: (size: { width: string | number; height: string | number }) => void;
  loginGoogle: () => void;
  loginPhone: (phone: string) => void;
  loginAttempted?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
  errorMessage?: ReactNode;
  disableSSOAuth?: boolean;
};

export default function Login({
  name,
  active = false,
  updateContentSize = () => {},
  loginPhone,
  loginGoogle,
  loginAttempted,
  isLoading,
  hasError,
  errorMessage,
  disableSSOAuth,
}: LoginProps) {
  const [loginMethod, setLoginMethod] = useState<'google' | 'phone'>();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneInputValue, setPhoneInputValue] = useState('');
  const [phoneIsValid, setPhoneIsValid] = useState(false);

  const isMobile = useMedia('(max-width: 439px)');
  const handleGoogleLoginClick = useCallback(() => {
    setLoginMethod('google');
    loginGoogle();
  }, [loginGoogle]);

  const handlePhoneInputFocus = useCallback(
    ({ target: { value } }: FocusEvent<HTMLInputElement>) => {
      setLoginMethod('phone');
      if (value.trim() === '') {
        setPhoneInputValue('+1 ');
      }
    },
    [],
  );

  const handlePhoneInputBlur = useCallback(
    ({ target: { value } }: FocusEvent<HTMLInputElement>) => {
      if (value.trim() === '+1') {
        setPhoneInputValue('');
      }
    },
    [],
  );

  const handlePhoneInputChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (value.trim() === '') {
        setPhoneInputValue('+1 ');
        if (phoneIsValid) {
          setPhoneIsValid(false);
        }
      } else {
        const phone = cleanNumber(value);
        setPhoneNumber(phone);

        const phoneFormatted = formatPhoneInput(phone);
        setPhoneInputValue(phoneFormatted);

        const phoneTest = phone.replace(/^1/, '');
        if (!phoneIsValid && isPhone(phoneTest)) {
          setPhoneIsValid(true);
        } else if (value && !isPhone(phoneTest)) {
          setPhoneIsValid(false);
        }
      }
    },
    [phoneIsValid],
  );

  const handlePhoneLogin = useCallback(() => {
    loginPhone(phoneNumber);
  }, [loginPhone, phoneNumber]);

  // eslint-disable-next-line no-nested-ternary
  const mobileHeight = disableSSOAuth ? 208 : KEYCLOAK_ENABLED ? 385 : 332;
  const desktopHeight = disableSSOAuth ? 220 : 282;

  return (
    <Step
      active={active}
      width={isMobile ? 320 : 460}
      height={isMobile ? mobileHeight : desktopHeight}
      name="login"
      updateContentSize={updateContentSize}
    >
      <Heading>Login to Metropolis</Heading>
      <Box
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        paddingBottom="12px"
      >
        <Box flexDirection="row" justifyContent="center" paddingTop="24px" maxWidth="240px">
          <Text fontSize="16px" lineHeight={1.35} textAlign="center">
            <span>Login with your phone number</span>
            {!disableSSOAuth && <div> or email</div>}
          </Text>
        </Box>
        <ButtonBox disableSSOAuth={disableSSOAuth && !KEYCLOAK_ENABLED}>
          <TopRow disableSSOAuth={disableSSOAuth && !KEYCLOAK_ENABLED}>
            <EnterPhoneBox>
              <EnterPhoneButton
                isHidden={loginMethod === 'phone'}
                onClick={() => setLoginMethod('phone')}
              >
                <span>Sign in with Phone</span>
              </EnterPhoneButton>
              <PhoneLoginFields isActive={loginMethod === 'phone'}>
                {loginMethod === 'phone' && (
                  <Input
                    required
                    type="code"
                    step="any"
                    label="Phone Number"
                    name="phone"
                    pattern="[0-9]*"
                    autoFocus
                    onChange={handlePhoneInputChange}
                    onFocus={handlePhoneInputFocus}
                    onBlur={handlePhoneInputBlur}
                    value={phoneInputValue}
                    isEmpty={phoneInputValue.length === 0}
                  />
                )}
                <PhoneLoginButton onClick={handlePhoneLogin} isEnabled={phoneIsValid}>
                  <ArrowRight width="20px" height="20px" fill={theme.colors.blue2} />
                </PhoneLoginButton>
              </PhoneLoginFields>
            </EnterPhoneBox>
            {!KEYCLOAK_ENABLED && !disableSSOAuth && (
              <GoogleAuthButton
                isHidden={loginMethod === 'phone'}
                onClick={handleGoogleLoginClick}
                loading={loginMethod === 'google' && isLoading}
              />
            )}
            {KEYCLOAK_ENABLED && (
              <div css="margin-top: 12px;">
                <KeycloakLoginButton />
              </div>
            )}
          </TopRow>
          <TopRow css={!KEYCLOAK_ENABLED && 'justify-content: center;'}>
            {KEYCLOAK_ENABLED && !disableSSOAuth && (
              <div css="margin-left: -3px;">
                <GoogleAuthButton
                  isHidden={loginMethod === 'phone'}
                  onClick={handleGoogleLoginClick}
                  loading={loginMethod === 'google' && isLoading}
                />
              </div>
            )}
            {!disableSSOAuth && (
              <div css="margin-top: 12px;">
                <MicrosoftSignInButton />
              </div>
            )}
          </TopRow>
        </ButtonBox>
        {loginAttempted && isLoading === false && hasError === true && (
          <Box
            position="absolute"
            top="100%"
            left={0}
            width="100%"
            alignItems="center"
            justifyContent="center"
            height="36px"
            paddingX="20px"
          >
            <AlertCircle width={18} height={18} css="margin-right: 6px" fill={theme.colors.coral} />
            <Text fontSize="15px">
              {loginMethod === 'phone'
                ? (errorMessage ?? 'No matching account found.')
                : 'Sign in failed. Please try again later.'}
            </Text>
          </Box>
        )}
      </Box>
    </Step>
  );
}
