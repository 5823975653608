import { Spinner } from './LoadingSpinnerWithBackground.styled';

type Props = {
  size?: number;
  color?: string;
  backgroundColor?: string;
  className?: string;
};

export default function LoadingSpinnerWithBackground({
  size = 24,
  color,
  backgroundColor,
  className,
}: Props) {
  return (
    <Spinner size={size} color={color} backgroundColor={backgroundColor} className={className} />
  );
}
