import { createStore, Effect } from 'effector';

import { applyReducers } from 'effector-utils';
import { IdNameObject } from 'types';

import { fetchForceCloseReasons } from './actions';

import type { DoneHandler } from 'effector-utils';
import type { APIResponse } from 'utils/http';

type State = {
  forceCloseReasons: IdNameObject[] | null;
};

const initialState: State = {
  forceCloseReasons: null,
};
const store = createStore(initialState);

type Reducers = {
  fetchForceCloseReasons: {
    action: Effect<Parameters<typeof fetchForceCloseReasons>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchForceCloseReasons>[0], State>;
  };
};

export const reducers: Reducers = {
  fetchForceCloseReasons: {
    action: fetchForceCloseReasons,
    done: (state, { result: { success, data } }) => ({
      ...state,
      forceCloseReasons: success === true ? data.forceCloseReasons : null,
    }),
  },
};

export default applyReducers({
  store,
  reducers,
});
