import React, { useCallback, useEffect, useState } from 'react';

import { useUnit } from 'effector-react';
import { useHistory } from 'react-router-dom';
import { useLockBodyScroll, useToggle } from 'react-use';

import Login from 'apps/specialist/components/Login';
import { MultiStepContent, Overlay } from 'apps/specialist/components/PageOverlay';
import VerifyCode from 'apps/specialist/components/VerifyCode';
import { useIsAuthed } from 'apps/specialist/hooks/useIsAuthed';
import {
  login,
  getUser,
  userStore,
  requestLoginCode,
  requestIntakeLoginCode,
  verifyCode,
  showLoginPrompt as setLoginVisible,
} from 'apps/specialist/state/user';
import { getCurrentAppName } from 'utils/currentApp';

import { setUserEnabledSites } from 'state/site';

import { LoginPromptStepName } from './LoginPromptContainer.types';

type LoginPromptContainerProps = {
  disableSSOAuth?: boolean;
};

function LoginPromptContainer({ disableSSOAuth = false }: LoginPromptContainerProps) {
  const history = useHistory();
  const {
    user,
    showLoginPrompt,
    status: {
      getUserPending,
      getUserSuccess,
      loginPending,
      loginSuccess,
      requestLoginCodePending,
      requestLoginCodeSuccess,
      requestIntakeLoginCodePending,
      requestIntakeLoginCodeSuccess,
      verifyCodePending,
      verifyCodeSuccess,
    },
    statusMessage: { requestLoginCodeMessage, requestIntakeLoginCodeMessage, verifyCodeMessage },
  } = useUnit(userStore);

  const isAuthed = useIsAuthed();

  const [locked, toggleLocked] = useToggle(false);

  useLockBodyScroll(locked);
  const [activeStep, setActiveStep] = useState<LoginPromptStepName | null>(null);
  const [phone, setPhone] = useState('');
  const loginAttempted =
    loginPending !== null ||
    requestLoginCodePending !== null ||
    requestIntakeLoginCodePending !== null;
  const isLoading = !!(getUserPending || loginPending || verifyCodePending);
  const hasError = !(
    getUserSuccess === true ||
    loginSuccess === true ||
    verifyCodeSuccess === true
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isAuthed) {
      getCurrentAppName();
      setLoginVisible();
      setActiveStep('login');
      toggleLocked(true);
    } else if (user) {
      setUserEnabledSites(user.sites);
      history.replace('/');
      toggleLocked(false);
    }
  }, [isLoading, isAuthed, history, user, toggleLocked]);

  useEffect(() => {
    if (
      !isAuthed &&
      activeStep !== 'verify-code' &&
      (requestLoginCodeSuccess || requestIntakeLoginCodeSuccess)
    ) {
      setActiveStep('verify-code');
    }
  }, [activeStep, isAuthed, requestIntakeLoginCodeSuccess, requestLoginCodeSuccess]);

  const handlePhoneLoginRequest = useCallback((phoneNumber: string) => {
    setPhone(phoneNumber);
    const currentApp = getCurrentAppName();
    if (currentApp === 'intake' || currentApp === 'request') {
      requestIntakeLoginCode({ phoneNumber });
    } else {
      requestLoginCode({ phoneNumber });
    }
  }, []);

  const handleVerifyCode = useCallback(
    async (code: string) => {
      const response = await verifyCode({
        phoneNumber: phone,
        code,
        beginSession: true,
      });
      if (response.success) {
        await getUser();
        history.replace('/');
        toggleLocked(false);
      }
    },
    [phone, history, toggleLocked],
  );

  return (
    <Overlay visible={showLoginPrompt}>
      <MultiStepContent activeStepName={activeStep}>
        <Login
          name="login"
          loginPhone={handlePhoneLoginRequest}
          loginGoogle={login}
          loginAttempted={loginAttempted}
          isLoading={isLoading}
          hasError={
            hasError &&
            (requestLoginCodeSuccess === false || requestIntakeLoginCodeSuccess === false)
          }
          errorMessage={requestLoginCodeMessage ?? requestIntakeLoginCodeMessage}
          disableSSOAuth={disableSSOAuth}
        />
        <VerifyCode
          name="verify-code"
          onSubmit={handleVerifyCode}
          isLoading={isLoading}
          hasError={verifyCodeSuccess === false}
          errorMessage={verifyCodeMessage}
        />
      </MultiStepContent>
    </Overlay>
  );
}

export default React.memo(LoginPromptContainer);
