import { lighten } from 'polished';
import styled, { css } from 'styled-components';

import Input from 'apps/specialist/components/TextInput';
import GAuthButton from 'components/GoogleAuthButton';
import { Button } from 'components/uikit';
import fullTheme, { withStyledSystem } from 'theme';

import type { StyledSystemProps } from 'theme';

export const EnterPhoneBox = styled.div`
  display: block;
  width: 186px;
  height: 48px;
  position: relative;
`;

export const GoogleAuthButton = styled(GAuthButton)<{ isHidden?: boolean }>`
  margin-top: 12px;
`;

export const EnterPhoneButton = styled(Button)<{ isHidden: boolean }>`
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  flex-shrink: 0;
  height: 41px;
  padding-bottom: 1px;
  border-radius: 3px;
  pointer-events: ${({ isHidden }) => (isHidden ? 'none' : 'auto')};
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  transform: scale3d(
    ${({ isHidden }) => (isHidden ? 0 : 1)},
    ${({ isHidden }) => (isHidden ? 0 : 1)},
    1
  );
  transition-property: opacity, transform;
  transition-timing-function: linear, ease-in;
  transition-duration: ${({ isHidden }) => (isHidden ? '100ms,250ms' : '300ms,250ms')};
  transition-delay: 50ms;
  border: 1px solid ${({ theme }) => lighten(0.04, theme.colors.grey7)} !important;

  @media (min-width: 440px) {
    padding-bottom: 0;
    top: 9px;
    height: 42px;
  }
`;
EnterPhoneButton.defaultProps = {
  type: 'button',
  variant: 'primary',
  theme: fullTheme,
};

export const PhoneLoginFields = styled.div<{ isActive: boolean }>`
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  padding-bottom: 12px;
  flex-shrink: 0;
  pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transform: translate3d(0, ${({ isActive }) => (isActive ? -10 : 0)}px, 0)
    scale3d(${({ isActive }) => (isActive ? 1 : 0.5)}, ${({ isActive }) => (isActive ? 1 : 0.5)}, 1);
  transition-property: opacity, transform;
  transition-timing-function: linear, ease-out;
  transition-duration: ${({ isActive }) => (isActive ? '300ms,250ms' : '150ms,250ms')};
  transition-delay: ${({ isActive }) => (isActive ? '300ms' : '0ms')};

  & ${Input} {
    height: 64px !important;
    flex-shrink: 0;
  }

  @media (min-width: 440px) {
    margin-bottom: 0;
    padding-right: 28px;
  }
`;

export const PhoneLoginButton = styled(Button)<{ isEnabled: boolean }>`
  position: absolute;
  top: 20px;
  right: -20px;
  z-index: 2;
  width: 34px;
  height: 36px;
  border: 1px solid ${({ theme }) => lighten(0.04, theme.colors.grey7)} !important;
  border-radius: 4px;
  padding: 0;
  pointer-events: ${({ isEnabled }) => (isEnabled ? 'auto' : 'none')};
  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0)};
  transform: translate3d(${({ isEnabled }) => (isEnabled ? 0 : -8)}px, 0, 0)
    scale3d(
      ${({ isEnabled }) => (isEnabled ? 1 : 0.35)},
      ${({ isEnabled }) => (isEnabled ? 1 : 0.35)},
      1
    );
  transition-property: opacity, transform;
  transition-timing-function: linear, ease-out;
  transition-duration: ${({ isEnabled }) => (isEnabled ? '150ms,150ms' : '50ms,150ms')};

  &:active {
    & svg {
      opacity: 0.5;
    }
  }

  @media (min-width: 440px) {
    right: -8px;
  }
`;
PhoneLoginButton.defaultProps = {
  type: 'button',
  variant: 'primary',
  theme: fullTheme,
};

export const TopRow = styled.div<{ disableSSOAuth?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (min-width: 440px) {
    margin-top: 0;
    flex-direction: row;
    align-items: center;
    justify-content: ${({ disableSSOAuth }) => (disableSSOAuth ? 'center' : 'space-between')};
  }
`;

export const ButtonBox = styled.div<StyledSystemProps & { disableSSOAuth?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ disableSSOAuth }) => (disableSSOAuth ? 'center' : 'flex-start')};
  align-items: center;
  height: auto;
  flex-grow: 0;
  padding-bottom: 6px;

  @media (min-width: 440px) {
    width: 412px;
    flex-direction: column;
    justify-content: ${({ disableSSOAuth }) => (disableSSOAuth ? 'center' : 'space-between')};
  }

  & ${EnterPhoneBox} {
    ${({ disableSSOAuth }) =>
      disableSSOAuth &&
      css`
        width: 206px;
        margin-bottom: 10px;
      `}
  }

  & ${PhoneLoginFields} {
    ${({ disableSSOAuth }) =>
      disableSSOAuth &&
      css`
        padding-right: 0 !important;
      `}
  }

  & ${PhoneLoginButton} {
    ${({ disableSSOAuth }) =>
      disableSSOAuth &&
      css`
        right: 0 !important;
      `}
  }
  ${withStyledSystem}
`;
