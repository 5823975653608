import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import withScrollLock from 'utils/withScrollLock';

import styles from './PageOverlay.module.css';

type PriorityOverlayProps = {
  visible: boolean;
  actionRequired: boolean;
  children: JSX.Element;
  scrollLock: {
    request: () => void;
    release: () => void;
  };
  onBackgroundClick: () => void;
};

function PriorityOverlay({
  visible = false,
  actionRequired = true,
  children,
  scrollLock,
  onBackgroundClick = () => {},
}: PriorityOverlayProps) {
  const [emphasize, setEmphasize] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (visible) {
      scrollLock.request();
    } else {
      scrollLock.release();
    }
    return () => {
      if (visible) {
        scrollLock.release();
      }
    };
  }, [visible, scrollLock]);

  const emphasizeContent = (e: React.MouseEvent | React.TouchEvent) => {
    if (actionRequired && e.target === containerRef.current) {
      setEmphasize(true);
    }
    if (e.target === containerRef.current) {
      onBackgroundClick();
    }
  };

  return (
    <div
      ref={containerRef}
      role="presentation"
      className={cn(styles.container, {
        [styles.visible]: visible,
        [styles.emphasize]: actionRequired && emphasize,
      })}
      onClick={emphasizeContent}
      onAnimationEnd={() => {
        if (actionRequired) {
          setEmphasize(false);
        }
      }}
    >
      {children}
    </div>
  );
}
PriorityOverlay.displayName = 'PriorityOverlay';

export default withScrollLock(PriorityOverlay);
