import { useTheme } from 'styled-components';

import BrandButton from 'components/buttons/BrandButton';
import { Heading } from 'components/uikit';

import {
  LoginProgressBox,
  LoginProgressContent,
  LoginProgressHeader,
  SpinnerBox,
  LoadingSpinner,
  LogoSpinnerBox,
  LogoSpinner,
} from './LoginProgress.styled';

interface LoginProgressProps {
  darkMode?: boolean;
  errorMessage?: string | null;
  errorRedirect?: () => void;
}

export default function LoginProgress({
  darkMode,
  errorMessage,
  errorRedirect,
}: LoginProgressProps) {
  const theme = useTheme();
  return (
    <LoginProgressBox>
      <LoginProgressContent darkMode={darkMode}>
        <LoginProgressHeader darkMode={darkMode}>
          <Heading
            as="h3"
            fontSize="h6"
            letterSpacing="0.02em"
            margin={0}
            color={darkMode ? theme.colors.white : undefined}
          >
            {errorMessage || 'Logging into Metropolis'}
          </Heading>
        </LoginProgressHeader>
        {!errorMessage && (
          <SpinnerBox>
            <LoadingSpinner />
            <LogoSpinnerBox>
              <LogoSpinner />
            </LogoSpinnerBox>
          </SpinnerBox>
        )}
        {!!errorMessage && (
          <SpinnerBox css="padding: 16px;">
            <BrandButton onClick={errorRedirect} textCenter>
              Return to login
            </BrandButton>
          </SpinnerBox>
        )}
      </LoginProgressContent>
    </LoginProgressBox>
  );
}
