import { createStore, Effect, Event } from 'effector';

import { getUser, login, loginMicrosoft, logout } from 'apps/specialist/state/user';
import { applyReducers, DoneHandler } from 'effector-utils';
import { APIResponse } from 'utils/http';

import {
  fetchSiteRateSchedules,
  getSite,
  getSiteOccupancy,
  getSiteTipSettings,
  getSiteValidations,
  setUserEnabledSites,
  updateSiteOccupancy,
  getSiteGates,
} from './actions';

import type { Rate, RateSchedule, Site, SiteTipSettings, Validation, GateLane } from 'types/api';

export type State = {
  userSites: Site[];
  sortedUserSites: Site[];
  sites: {
    [siteId: number]: Site & { rate: Rate };
  };
  siteRateSchedulesMap: {
    [siteId: number]: RateSchedule | null;
  };
  siteOccupancyMap: {
    [siteId: number]: number | null;
  };
  siteTipSettingsMap: {
    [siteId: number]: SiteTipSettings | null;
  };
  siteValidationsMap: {
    [siteId: number]: Validation[];
  };
  siteGates: GateLane[];
};

const initialState: State = {
  userSites: [],
  sortedUserSites: [],
  sites: {},
  siteOccupancyMap: {},
  siteRateSchedulesMap: {},
  siteTipSettingsMap: {},
  siteValidationsMap: {},
  siteGates: [],
};
const store = createStore(initialState);

type Reducers = {
  setUserEnabledSites: {
    action: Event<Site[]>;
    reducer: (state: State, payload: Site[]) => State;
  };
  getUser: {
    action: Effect<Parameters<typeof getUser>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getUser>[0], State>;
  };
  login: {
    action: Effect<Parameters<typeof login>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof login>[0], State>;
  };
  loginMicrosoft: {
    action: Effect<Parameters<typeof loginMicrosoft>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof loginMicrosoft>[0], State>;
  };
  logout: {
    action: Effect<Parameters<typeof logout>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof logout>[0], State>;
  };
  getSite: {
    action: Effect<Parameters<typeof getSite>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSite>[0], State>;
  };
  getSiteOccupancy: {
    action: Effect<Parameters<typeof getSiteOccupancy>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSiteOccupancy>[0], State>;
  };
  getSiteTipSettings: {
    action: Effect<Parameters<typeof getSiteTipSettings>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSiteTipSettings>[0], State>;
  };
  getSiteValidations: {
    action: Effect<Parameters<typeof getSiteValidations>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSiteValidations>[0], State>;
  };
  updateSiteOccupancy: {
    action: Effect<Parameters<typeof updateSiteOccupancy>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateSiteOccupancy>[0], State>;
  };
  fetchSiteRateSchedules: {
    action: Effect<Parameters<typeof fetchSiteRateSchedules>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchSiteRateSchedules>[0], State>;
  };
  getSiteGates: {
    action: Effect<Parameters<typeof getSiteGates>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSiteGates>[0], State>;
  };
};

export function sortSites<T extends { name?: string }>(sites: T[]) {
  return [...sites].sort((siteA, siteB) => {
    const siteAName = siteA?.name?.toLowerCase();
    const siteBName = siteB?.name?.toLowerCase();

    if (!siteAName && !siteBName) {
      return 0;
    }
    if (!siteAName || !siteBName) {
      return siteAName ? 1 : -1;
    }
    if (siteAName < siteBName) {
      return -1;
    }
    if (siteAName > siteBName) {
      return 1;
    }

    return 0;
  });
}

export const reducers: Reducers = {
  setUserEnabledSites: {
    action: setUserEnabledSites,
    reducer: (state, sites) => ({
      ...state,
      userSites: sites,
      sortedUserSites: sortSites(sites),
    }),
  },
  getUser: {
    action: getUser,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            userSites: data.user.sites,
            sortedUserSites: sortSites(data.user.sites),
          }
        : state,
  },
  login: {
    action: login,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            userSites: data.user.sites,
            sortedUserSites: sortSites(data.user.sites),
          }
        : state,
  },
  loginMicrosoft: {
    action: loginMicrosoft,
    done: (state, { result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            userSites: data.user.sites,
            sortedUserSites: sortSites(data.user.sites),
          }
        : state,
  },
  logout: {
    action: logout,
    done: (state, { result: { success } = {} }) =>
      success
        ? {
            ...state,
            userSites: [],
            sortedUserSites: [],
          }
        : state,
  },
  getSite: {
    action: getSite,
    done: (state, { result: { success, data }, params: { siteId } }) => {
      if (success) {
        const newState = {
          ...state,
          sites: { ...state.sites },
        };
        newState.sites[siteId] = {
          ...newState.sites[siteId],
          ...data,
        };
        return newState;
      }
      return state;
    },
  },
  getSiteOccupancy: {
    action: getSiteOccupancy,
    done: (state, { params: { siteId }, result: { success, data } }) => ({
      ...state,
      siteOccupancyMap: {
        ...state.siteOccupancyMap,
        [siteId]: success ? data.count : null,
      },
    }),
  },
  getSiteTipSettings: {
    action: getSiteTipSettings,
    done: (state, { params: { siteId }, result: { success, data } }) => ({
      ...state,
      siteTipSettingsMap: {
        ...state.siteTipSettingsMap,
        [siteId]: success
          ? {
              suggestedTipAmounts: data.suggestedTipAmounts,
              tippingEnabled: data.tippingEnabled,
            }
          : null,
      },
    }),
  },
  getSiteValidations: {
    action: getSiteValidations,
    done: (state, { params: { siteId }, result: { success, data } }) => {
      if (success) {
        const nextSiteValidationsMap = { ...state.siteValidationsMap };
        const siteValidationOptions = data.sort((a: Validation, b: Validation) =>
          a.vendorName.localeCompare(b.vendorName),
        );
        nextSiteValidationsMap[siteId] = siteValidationOptions;
        return {
          ...state,
          siteValidationsMap: nextSiteValidationsMap,
        };
      }
      return state;
    },
  },
  updateSiteOccupancy: {
    action: updateSiteOccupancy,
    done: (state, { params: { siteId }, result: { success, data } }) => {
      if (success) {
        return {
          ...state,
          siteOccupancyMap: {
            ...state.siteOccupancyMap,
            [siteId]: data.count,
          },
        };
      }
      return state;
    },
  },
  fetchSiteRateSchedules: {
    action: fetchSiteRateSchedules,
    done: (state, { params: { siteId }, result: { success, data } }) => {
      if (success) {
        return {
          ...state,
          siteRateSchedulesMap: {
            ...state.siteRateSchedulesMap,
            [siteId]: data.rateSchedule,
          },
        };
      }
      return state;
    },
  },
  getSiteGates: {
    action: getSiteGates,
    done: (state, { result: { success, data } }) => {
      if (success) {
        return {
          ...state,
          siteGates: data,
        };
      }
      return state;
    },
  },
};

export default applyReducers({ store, reducers });
