import { msalInstance } from 'utils/msalConfig';

export const MsalService = {
  /**
   * Gets the Microsoft access token after logging in with SSO
   */
  getLoginInfo: async () => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.',
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      account,
      scopes: [],
    });

    return {
      email: response.account?.username,
      code: response.accessToken,
    };
  },
};
